import { oneSpaRoutePathsFollowup } from '@packages/core/lib/routePaths/followupRoutePaths';
import { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import { RouteLoader } from '~/components/Loader/RouteLoader';

const Followup = lazy(() => import('@apps/followup/central-routes/react-routes'));

const FollowupOverview = lazy(() =>
  import('@apps/followup/central-routes/react-routes').then((module) => ({
    default: module.FollowupOverview,
  })),
);
const FollowupDatapage = lazy(() =>
  import('@apps/followup/central-routes/react-routes').then((module) => ({
    default: module.FollowupDatapage,
  })),
);
const FollowupAnalysispage = lazy(() =>
  import('@apps/followup/central-routes/react-routes').then((module) => ({
    default: module.FollowupAnalysispage,
  })),
);
const FollowupInvoices = lazy(() =>
  import('@apps/followup/central-routes/react-routes').then((module) => ({
    default: module.FollowupInvoices,
  })),
);
const FollowupDocuments = lazy(() =>
  import('@apps/followup/central-routes/react-routes').then((module) => ({
    default: module.FollowupDocuments,
  })),
);

export const FollowupRouteTree = (
  <Route
    path={oneSpaRoutePathsFollowup.HOME}
    element={
      <Suspense fallback={<RouteLoader />}>
        <Followup />
      </Suspense>
    }
  >
    <Route index path={oneSpaRoutePathsFollowup.HOME} element={<FollowupOverview />} />
    <Route path={oneSpaRoutePathsFollowup.DATA} element={<FollowupDatapage />} />
    <Route path={oneSpaRoutePathsFollowup.DATAPAGE_W_SETTINGS} element={<FollowupDatapage />} />
    <Route
      path={oneSpaRoutePathsFollowup.DATAPAGE_ARTICLES_SETTINGS_ARTICLEBREAKDOWN}
      element={<FollowupDatapage />}
    />
    <Route path={oneSpaRoutePathsFollowup.ANALYSIS} element={<FollowupAnalysispage />} />
    <Route path={oneSpaRoutePathsFollowup.ANALYSIS_W_SETTINGS} element={<FollowupAnalysispage />} />
    <Route
      path={oneSpaRoutePathsFollowup.ANALYSIS_W_SETTINGS_SUBGRAPH}
      element={<FollowupAnalysispage />}
    />
    <Route path={oneSpaRoutePathsFollowup.INVOICES} element={<FollowupInvoices />} />
    <Route path={oneSpaRoutePathsFollowup.DOCUMENTS} element={<FollowupDocuments />} />
    <Route path={oneSpaRoutePathsFollowup.NOTE_OBLIGATION} element={<FollowupDocuments />} />
    <Route
      path={oneSpaRoutePathsFollowup.CERTIFICATE_OF_DESTRUCTION}
      element={<FollowupDocuments />}
    />
    <Route path={oneSpaRoutePathsFollowup.ANIMAL_BY_PRODUCTS} element={<FollowupDocuments />} />
  </Route>
);

export const preloadFollowup = () => {
  //Import default export to trigger preload
  import('@apps/followup/central-routes/react-routes');
};
