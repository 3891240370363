import { oneSpaRoutePathsOrderPickup } from '@packages/core/lib/routePaths/pickupsRoutePaths';
import { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import { RouteLoader } from '~/components/Loader/RouteLoader';

const OrderPickup = lazy(() => import('@apps/order-pickup-desktop/central-routes/pickups-routes'));

const PickupsRoute = lazy(() =>
  import('@apps/order-pickup-desktop/central-routes/pickups-routes').then((module) => ({
    default: module.PickupsRoute,
  })),
);

const PickupsConfirmationRoute = lazy(() =>
  import('@apps/order-pickup-desktop/central-routes/pickups-routes').then((module) => ({
    default: module.ConfirmationRoute,
  })),
);

const BatteryPickupRoute = lazy(() =>
  import('@apps/order-pickup-desktop/central-routes/pickups-routes').then((module) => ({
    default: module.BatteryPickupRoute,
  })),
);

const RequestBatteryPickupRoute = lazy(() =>
  import('@apps/order-pickup-desktop/central-routes/pickups-routes').then((module) => ({
    default: module.RequestBatteryPickupRoute,
  })),
);

const BatteryConfirmationRoute = lazy(() =>
  import('@apps/order-pickup-desktop/central-routes/pickups-routes').then((module) => ({
    default: module.BatteryConfirmationRoute,
  })),
);

const PreviousOrdersRoute = lazy(() =>
  import('@apps/order-pickup-desktop/central-routes/pickups-routes').then((module) => ({
    default: module.PreviousOrdersRoute,
  })),
);

const PickupScheduleRoute = lazy(() =>
  import('@apps/order-pickup-desktop/central-routes/pickups-routes').then((module) => ({
    default: module.PickupsScheduleRoute,
  })),
);

const DeclarationRoute = lazy(() =>
  import('@apps/order-pickup-desktop/central-routes/pickups-routes').then((module) => ({
    default: module.DeclarationRoute,
  })),
);

const RentedContainersRoute = lazy(() =>
  import('@apps/order-pickup-desktop/central-routes/pickups-routes').then((module) => ({
    default: module.RentedContainersRoute,
  })),
);

export const PickupsRouteTree = (
  <Route
    path={oneSpaRoutePathsOrderPickup.ORDER_PICKUP}
    element={
      <Suspense fallback={<RouteLoader />}>
        <OrderPickup />
      </Suspense>
    }
  >
    <Route index element={<PickupsRoute />} />
    <Route path={oneSpaRoutePathsOrderPickup.CONFIRMATION}>
      <Route path=":confirmationId" element={<PickupsConfirmationRoute />} />
    </Route>
    <Route path={oneSpaRoutePathsOrderPickup.LI_ION_BATTERIES}>
      <Route index element={<BatteryPickupRoute />} />
      <Route
        path={oneSpaRoutePathsOrderPickup.LI_ION_BATTERIES_REQUEST_PICKUP}
        element={<RequestBatteryPickupRoute />}
      />
      <Route
        path={oneSpaRoutePathsOrderPickup.LI_ION_BATTERIES_CONFIRMATION}
        element={<BatteryConfirmationRoute />}
      />
    </Route>
    <Route path={oneSpaRoutePathsOrderPickup.PREVIOUS_ORDERS} element={<PreviousOrdersRoute />} />
    <Route path={oneSpaRoutePathsOrderPickup.PICKUP_SCHEDULE} element={<PickupScheduleRoute />} />
    <Route path={oneSpaRoutePathsOrderPickup.DECLARATION} element={<DeclarationRoute />} />
    <Route
      path={oneSpaRoutePathsOrderPickup.RENTED_CONTAINERS}
      element={<RentedContainersRoute />}
    />
  </Route>
);

export const preloadPickups = () => {
  //Import default export to trigger preload
  void import('@apps/order-pickup-desktop/central-routes/pickups-routes');
};
