export const oneSpaRoutePathsOrderPickup = {
  ORDER_PICKUP: '/pickups',
  CONFIRMATION: '/pickups/confirmation',
  CONFIRMATION_ID: '/pickups/confirmation/:confirmationId',
  LI_ION_BATTERIES: '/pickups/li-ion-batteries',
  LI_ION_BATTERIES_REQUEST_PICKUP: '/pickups/li-ion-batteries/request-pickup',
  LI_ION_BATTERIES_CONFIRMATION: '/pickups/li-ion-batteries/confirmation/:confirmationId',
  PREVIOUS_ORDERS: '/pickups/previous-orders/',
  PICKUP_SCHEDULE: '/pickups/pickup-schedule',
  DECLARATION: '/pickups/declaration',
  RENTED_CONTAINERS: '/pickups/rented-containers',
} as const;
