export const oneSpaRoutePathsFollowup = {
  HOME: '/followup',
  OVERVIEW: '/followup/overview/',
  DATA: '/followup/data/',
  DATAPAGE_W_SETTINGS: '/followup/data/:context',
  DATAPAGE_ARTICLES_SETTINGS_ARTICLEBREAKDOWN: '/followup/data/standard/:article',
  ANALYSIS: '/followup/analysis/',
  ANALYSIS_W_SETTINGS: '/followup/analysis/:category/:graphType',
  ANALYSIS_W_SETTINGS_SUBGRAPH: '/followup/analysis/:category/:graphType/:subGraphType',
  INVOICES: '/followup/invoices',
  DOCUMENTS: '/followup/documents',
  NOTE_OBLIGATION: '/followup/documents/note-obligation',
  CERTIFICATE_OF_DESTRUCTION: '/followup/documents/certificate-of-destruction',
  ANIMAL_BY_PRODUCTS: '/followup/documents/animal-by-products',
} as const;
